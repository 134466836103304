<template>
  <div class="bbs-box">
    <div class="avatar">
      <div class="avatar-box" @click="goDetail(item.id)">
        <van-image width="12.27vw" :src="item.headImg" />
        <div class="avatar-title">
          <p>{{ item.nickName }}</p>
          <span>{{ item.timeStr }}</span>
        </div>
      </div>

      <van-popover v-model="showPopover" trigger="click" :overlay="true" :offset="[15, -15]" placement="bottom-end">
        <div class="check-box">
          <div class="left" @click="goShield(item.userId)">屏蔽</div>
          <p></p>
          <div class="right" @click="goFeedback(item.id)">举报</div>
        </div>
        <template #reference>
          <div class="spot" @click="showPopover = false">
            <p></p>
            <p></p>
            <p></p>
          </div>
        </template>
      </van-popover>
    </div>
    <div class="content">
      <p class="text" @click="goDetail(item.id)">{{ item.content }}</p>
      <div class="img-box" v-if="item.imgUrl">
        <van-image
          width="27vw"
          height="27vw"
          fit="cover"
          v-for="(e, i) in item.imgUrl.split(',')"
          :src="e"
          :key="i"
          :show-loading="false"
          @click="clickImg(item.imgUrl, i)"
        />
      </div>
      <div class="toolbar">
        <div @click="goDetail(item.id)">
          <van-image width="5.6vw" :src="require('@/assets/images/icon/mark.png')" />
          <span>{{ item.commentNum }}</span>
        </div>
        <div @click="goTags(item.id)">
          <van-image
            v-show="item.isSupport == 0"
            width="5.33vw"
            height="5.07vw"
            :show-loading="false"
            :src="require('@/assets/images/icon/heart.png')"
          />
          <van-image
            v-show="item.isSupport == 1"
            width="5.33vw"
            height="5.07vw"
            :show-loading="false"
            :src="require('@/assets/images/icon/red-heart.png')"
          />
          <span>{{ item.supportNum }}</span>
        </div>
      </div>
      <div class="line"></div>
    </div>
  </div>
</template>

<script>
import Http from '@/utils/Http.js';

import { Image as VanImage, ImagePreview, Popover } from 'vant';

import { showLoading, hideLoading, showToast } from '@/utils/VantUtils';
import { handlerEror } from '@/utils/Tools';

import { OPEN_NEW_WEBVIEW_IP, CLOSE_WEBVIEW } from '@/utils/Agreement.js';

const CommunitySupport = 'cloud/ec/tally/communitySupport';
const Shield = 'cloud/ec/tally/shield';

export default {
  components: {
    VanImage,
    VanPopover: Popover,
    [ImagePreview.Component.name]: ImagePreview.Component
  },
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      disabled: false,
      show: false,
      images: [],
      showPopover: false
    };
  },
  methods: {
    clickImg(e, i) {
      ImagePreview({
        images: e.split(','),
        startPosition: i
      });
    },
    goTags(id) {
      if (this.disabled) {
        return;
      }
      this.disabled = true;

      Http.get(CommunitySupport, { communityId: id })
        .then((data) => {
          if (this.item.isSupport == 0) {
            this.item.isSupport = 1;
            this.item.supportNum++;
            return;
          }
          this.item.isSupport = 0;
          this.item.supportNum--;
        })
        .catch((err) => {
          handlerEror(err);
        })
        .finally(() => {
          this.disabled = false;
        });
    },
    goDetail(id) {
      if (this.item.origin == 'detail') {
        return;
      }
      window.location.href = `${OPEN_NEW_WEBVIEW_IP}/accounts/detail?id=${id}`;
    },
    goFeedback(id) {
      window.location.href = `${OPEN_NEW_WEBVIEW_IP}/accounts/feedback?id=${id}`;
      this.showPopover = false;
    },
    goShield(id) {
      showLoading();
      Http.get(Shield, { userId: id })
        .then(() => {
          hideLoading();
          showToast('已屏蔽该用户');

          if (this.item.origin == 'detail') {
            setTimeout(() => {
              window.location.href = CLOSE_WEBVIEW;
            }, 400);
            return;
          }
          this.showPopover = false;
          this.$emit('onRefresh');
        })
        .catch((err) => {
          hideLoading();
          handlerEror(err);
        });
    }
  },
  created() {}
};
</script>

<style lang="less" scoped>
.bbs-box {
  padding: 20px 20px 0;
  .avatar {
    display: flex;
    justify-content: space-between;
  }
  .avatar-box {
    display: flex;
    align-items: center;
    .van-image {
      border-radius: 99px;
      overflow: hidden;
    }
    .avatar-title {
      margin-left: 10px;
      p {
        font-size: 14px;
        font-weight: 500;
        color: #1d1d1d;
      }
      span {
        font-size: 12px;
        font-weight: 400;
        color: #adadad;
      }
    }
  }
  .spot {
    p {
      width: 1px;
      height: 1px;
      border-radius: 50%;
      transform: scale(5);
      background: #1d1d1d;
      display: inline-block;
    }
    p:nth-child(2) {
      margin: 0 8px;
    }
  }
}
.content {
  padding: 0 6px;
  .text {
    font-size: 14px;
    font-weight: 400;
    color: #1d1d1d;
    margin: 10px 0;
  }
  .img-box {
    display: flex;
    flex-wrap: wrap;
    .van-image {
      margin-bottom: 10px;
      margin-right: 9px;
    }
    :nth-child(2n + 1).van-image {
      margin-right: 0;
    }
    :nth-child(1).van-image {
      margin-right: 9px;
    }
  }
  .toolbar {
    display: flex;
    justify-content: space-around;
    margin: 10px 0 20px;
    > div {
      display: flex;
      align-items: center;
      position: relative;
      span {
        opacity: 1;
        font-size: 14px;
        font-weight: 400;
        color: #adadad;
        position: absolute;
        right: -12px;
      }
    }
  }
  .line {
    height: 1px;
    background: #f2f2f2;
  }
}
.van-image {
  display: block;
}
.check-box {
  height: 38px;
  background: #ffffff;
  display: flex;
  align-items: center;
  div {
    font-size: 14px;
    font-weight: 500;
    color: #1d1d1d;
    width: 70px;
    text-align: center;
  }
  p {
    width: 1px;
    height: 20px;
    background: #d1d1d1;
  }
}
</style>
